import React from 'react'

import YoutubeBox from './YoutubeBox'
import PostCastBox from './PostcastBox'
import NewsletterBox from './NewsletterBox'

import { graphql } from 'gatsby'

export default function SubscriberBox({ subscribeType, ...rest }) {
   switch (subscribeType) {
      case 'video':
         return <YoutubeBox {...rest} />
      case 'postcast':
         return <PostCastBox {...rest} />
      case 'newsletter':
         return <NewsletterBox {...rest} />

      default:
         return null
   }
}

export const query = graphql`
   fragment SubscriberBox on ContentfulComponentSubscriberBox {
      subscribeType
      subscriberText {
         markdown: subscriberText
      }
      ctaText
      ctaUrl
   }
`
