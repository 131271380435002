import React from 'react'
import { graphql } from 'gatsby'
import isEmpty from 'lodash/isEmpty'

import { LangContext } from 'context'
import BridgeComponent from 'utils/connectSearchPageBody'
import Layout from 'components/Layout'

const Search = ({ location, pageContext, data }) => {
   const { featuredPosts, writingPosts, videoPosts, podcastPosts, page, categories, authors, header, footer } =
      data || {}
   const { lang } = pageContext || {}
   const { state, pathname } = location

   function renderPageSections(sections) {
      if (Array.isArray(sections)) {
         return sections.map((section) => {
            if (section.__typename === 'ContentfulPartialSearchData') {
               return (
                  <BridgeComponent
                     key={section.id}
                     __typename={section.__typename}
                     writingPosts={Array.isArray(writingPosts?.edges) ? writingPosts.edges : []}
                     podcastPosts={Array.isArray(podcastPosts?.edges) ? podcastPosts.edges : []}
                     videoPosts={Array.isArray(videoPosts?.edges) ? videoPosts.edges : []}
                     featuredPosts={Array.isArray(featuredPosts?.edges) ? featuredPosts.edges : []}
                     categories={Array.isArray(categories?.edges) ? categories.edges : []}
                     authors={Array.isArray(authors?.edges) ? authors.edges : []}
                     lang={lang}
                     data={section}
                     value={state && !isEmpty(state.searchQuery) && state.searchQuery}
                  />
               )
            }
            return <BridgeComponent key={section.id} {...section} />
         })
      }
      return <h2>This Page dont have any Components in Contentfull</h2>
   }

   return (
      <LangContext.Provider value={{ lang, path: pathname }}>
         <Layout
            pageTitle="Search"
            pageDescription="Search"
            location="/search"
            header={header}
            footer={footer}
            lang={lang}
         >
            {renderPageSections(page.body)}
         </Layout>
      </LangContext.Provider>
   )
}

export default Search

export const searchQuery = graphql`
   query searchQuery($lang: String!, $id: String!) {
      page: contentfulPage(id: { eq: $id }) {
         body {
            __typename
            ...PageIntro
            ...SearchData
         }
      }
      writingPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "writing" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      videoPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "videos" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      podcastPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "podcasts" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      featuredPosts: allContentfulPost(
         filter: { postCategories: { elemMatch: { categoryName: { eq: "featured" }, node_locale: { eq: $lang } } } }
         sort: { order: DESC, fields: [postDate] }
         limit: 5
      ) {
         edges {
            node {
               id
               postTitle
               slug
               postDate(formatString: "MMMM D, YYYY")
            }
         }
      }
      categories: allContentfulCategory(filter: { node_locale: { eq: $lang } }) {
         edges {
            node {
               id
               node_locale
               categoryName
               categoryLabel
            }
         }
      }
      authors: allContentfulPostAuthor(filter: { node_locale: { eq: $lang } }) {
         edges {
            node {
               id
               name
               profilePhoto {
                  gatsbyImage(height: 100, width: 100)
               }
               socialUrl
            }
         }
      }
      header: contentfulHeader(node_locale: { eq: $lang }) {
         searchPlaceholder
         headerLabel
         desktopNav {
            __typename
            ... on ContentfulLink {
               id
               name
               type
               url
               title
            }
         }
         mobileNav {
            id
            title
            type
            url
         }
         smallNav {
            id
            title
            type
            url
         }
      }
      footer: contentfulFooter(node_locale: { eq: $lang }) {
         menu {
            id
            title
            type
            url
         }
         smallMenu {
            id
            title
            type
            url
         }
         textContent {
            markdown: textContent
         }
         copyText {
            markdown: copyText
         }
         copyTitle
      }
   }
`
