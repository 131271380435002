import styled from 'styled-components'

export const StyledHeader = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-bottom: 8px;
   margin-bottom: 20px;
   border-bottom: solid 2px ${({ theme }) => theme.colors.dark};

   span {
      display: block;
      margin: 0;
      font-family: 'HeldaneDisplay';
      font-weight: 500;
      font-size: 24px;
      line-height: 31px;
      color: ${({ theme }) => (theme.darkMode ? theme.colors.white : theme.colors.dark)};
   }

   a {
      font-family: ${({ theme }) => theme.fonts.titillium};
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      transition: ${({ theme }) => theme.transitions.main};
      color: ${({ theme }) => theme.colors.tabLabel};

      &:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
      }
   }
`

export const StyledList = styled.ul`
   li {
      margin-bottom: 16px;

      &:last-of-type {
         margin-bottom: 0;
      }
   }

   a {
      display: block;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.3px;
      transition: ${({ theme }) => theme.transitions.main};

      &:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
         font-weight: 700;
      }
   }
`
