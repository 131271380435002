import React from 'react'
import styled from 'styled-components'
import Link from 'components/Link'

import media from 'utils/media'
import Markdown from 'components/Markdown'

import PhoenixWhite from 'assets/svg/phoenix_logo_white.svg'

const PostcastBox = ({ ctaText, subscriberText, ctaUrl }) => {
   return (
      <Box>
         {subscriberText?.markdown && <Markdown>{subscriberText.markdown}</Markdown>}
         {ctaUrl ? (
            <a title={ctaText} href={ctaUrl} target="_blank" rel="noopener noreferrer">
               <img height="20" src={PhoenixWhite} alt="phoenix" />
               {ctaText}
            </a>
         ) : (
            <Link type="internal" to="/podcasts">
               <img height="20" src={PhoenixWhite} alt="phoenix" />
               {ctaText}
            </Link>
         )}
      </Box>
   )
}

export default PostcastBox

const Box = styled.div`
   background: ${({ theme }) => theme.colors.subscribeBoxMedia};
   padding: 44px 38px;
   max-width: 100%;
   margin-bottom: 35px;

   ${media.greaterThan('md')`
      height: 300px;
  `};

   p {
      font-size: 23px;
      margin-bottom: 30px;
      font-weight: 400;
      line-height: 31px;
      letter-spacing: 0.43px;

      strong {
         font-weight: 700;
      }
   }

   a {
      text-decoration: none;
      color: #fff !important;
      height: 55px;
      width: 100%;
      border-radius: 4px;
      border: none;
      font-size: 18px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      box-shadow: 0 2px 2px 0 rgba(78, 77, 77, 0.5);
      background-color: ${({ theme }) => theme.colors.bismark};

      transition: 0.4s cubic-bezier(0, 0.89, 0.44, 1);

      &:hover {
         transform: translateY(-2px);
      }

      img {
         margin-right: 6px;
         margin-bottom: 0;
      }
   }
`
