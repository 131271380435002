import styled from 'styled-components'

import media from 'utils/media'

export const Section = styled.section`
   padding-top: 70px;
   padding-bottom: 45px;

   ${media.greaterThan('md')`
      padding-top: 96px;
      padding-bottom: 102px;
   `};
`
