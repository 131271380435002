import React from 'react'
import styled from 'styled-components'

import media from 'utils/media'
import Markdown from 'components/Markdown'
import Button from 'components/Button'
import videoIco from 'assets/svg/video.svg'

const YoutubeBox = ({ ctaUrl, ctaText, subscriberText }) => {
   return (
      <StyledWrapper>
         {subscriberText?.markdown && (
            <StyledText>
               <Markdown>{subscriberText.markdown}</Markdown>
            </StyledText>
         )}
         <StyledLink href={ctaUrl} target="_blank" rel="noreferrer">
            <Button small variant="accent">
               <div></div>
               {ctaText}
            </Button>
         </StyledLink>
      </StyledWrapper>
   )
}

export default YoutubeBox

const StyledWrapper = styled.div`
   padding: 24px;
   margin-bottom: 24px;
   background-color: ${({ theme }) => (theme.darkMode ? theme.colors.menuBg : theme.colors.grey_4)};
`

const StyledText = styled.div`
   margin-bottom: 16px;

   ${media.greaterThan('md')`
      margin-bottom: 24px;
   `};

   ${media.greaterThan('lg')`
      margin-bottom: 16px;
   `};

   h1,
   h2,
   h3 {
      font-size: 24px;
      line-height: 37px;
      color: ${({ theme }) => theme.colors.hotCinnamon};
      font-family: ${({ theme }) => theme.fonts.titillium};
      font-weight: 700;
      margin-bottom: 4px;
   }

   p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;

      &:last-of-type {
         margin-bottom: 0;
      }
   }
`

const StyledLink = styled.a`
   display: block;
   width: 100%;
   cursor: pointer;

   button {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      div {
         width: 20px;
         height: 20px;
         -webkit-mask-size: contain;
         mask-size: contain;
         -webkit-mask: url(${videoIco}) no-repeat center;
         mask: url(${videoIco}) no-repeat center;

         display: block;
         padding: 0;
         margin-right: 18px;
         background-color: ${({ theme }) => (theme.darkMode ? theme.colors.grey_3 : theme.colors.hotCinnamon)};
         line-height: 1;
         transition: ${({ theme }) => theme.transitions.main};

         svg {
            display: block;
         }
      }

      &:hover div {
         background-color: ${({ theme }) => theme.colors.white};
      }
   }
`
