import React, { useCallback } from 'react'
import { useHits } from 'react-instantsearch-hooks-web'
import styled from 'styled-components'

import media from 'utils/media'
import { generatePostSlug, parseLocalDate } from 'utils/helpers'
import PostThumbnail from 'components/PostThumbnail'

export default function Results({ resultsLabel, noResultsLabel, authors }) {
   const transformItems = useCallback(
      (items) =>
         items.map((item) => {
            const postAuthors = item.postAuthors
               ? item.postAuthors.map((el) => authors.find((author) => author.id === el.id))
               : []
            return {
               id: item.id,
               postDate: parseLocalDate(item.postDate, item.node_locale),
               slug: generatePostSlug(item),
               postCategories: item.postCategories,
               postTitle: item.postTitle,
               postAuthors,
               timeRead: item.timeRead,
               postExcerpt: { text: item.text },
               node_locale: item.node_locale,
            }
         }),
      [authors]
   )

   const { hits, results } = useHits({ transformItems })
   return (
      <StyledResults>
         {hits.length ? (
            <>
               <StyledTitle>{resultsLabel}</StyledTitle>

               {hits.map((item) => (
                  <PostThumbnail key={item.id} {...item} fullWidth />
               ))}
            </>
         ) : (
            <StyledTitle>
               {noResultsLabel}
               <strong>{results.query}</strong>
            </StyledTitle>
         )}
      </StyledResults>
   )
}

const StyledResults = styled.div`
   margin-bottom: 24px;
`

const StyledTitle = styled.span`
   display: block;
   padding: 0 24px;
   margin-bottom: 24px;
   font-size: 16px;
   line-height: 24px;
   text-transform: uppercase;

   ${media.greaterThan('sm')`
      padding: 0 50px;
   `};
`
