import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby'

import media from 'utils/media'
import { DEFAULT_LANG } from 'config/languages'

export default function PostCategories({ postCategories }) {
   function handleOnClick(event, url) {
      event.stopPropagation()
      navigate(url)
   }

   return (
      <StyledWrapper>
         {postCategories.map((cat) => {
            const url =
               cat.node_locale === DEFAULT_LANG ? `/${cat.categoryName}/` : `/${cat.node_locale}/${cat.categoryName}/`
            return (
               <button key={cat.categoryName} onClick={(e) => handleOnClick(e, url)}>
                  {cat.categoryLabel}
               </button>
            )
         })}
      </StyledWrapper>
   )
}

const StyledWrapper = styled.div`
   margin-bottom: 8px;

   button {
      margin: 0;
      padding: 0;
      cursor: pointer;
      background-color: transparent;
      border: none;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 1.5px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.hotCinnamon};
      text-transform: uppercase;

      ${media.greaterThan('md')`
         font-size: 16px;
         line-height: 24px;
         font-weight: 700;
      `};

      &:not(:last-child)::after {
         content: '•';
         margin: 0 5px;
      }
   }
`
