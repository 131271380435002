import React from 'react'
import posed from 'react-pose'
import styled from 'styled-components'

import SubscriberBox from 'components/SubscriberBox'
import CategorizedList from 'components/CategorizedList'

const Sidebar = ({ cta, lists, isAnimated }) => {
   return (
      <StaggerContainer pose={isAnimated ? 'open' : 'exit'}>
         {cta && (
            <StaggerChild className="no-js-visibility">
               <SubscriberBox {...cta} />
            </StaggerChild>
         )}

         <StyledLists className="desktop">
            {Object.keys(lists)
               .filter((key) => !!lists[key].posts.length)
               .map((key) => (
                  <StyledListElement className="no-js-visibility" key={key}>
                     <CategorizedList
                        title={lists[key].category.label}
                        list={lists[key].posts}
                        seeAllUrl={lists[key].category.url}
                     />
                  </StyledListElement>
               ))}
         </StyledLists>
      </StaggerContainer>
   )
}

const StaggerContainer = posed.div({
   open: {
      staggerChildren: 200,
   },
   initialPose: 'exit',
})

const StaggerChild = posed.div({
   open: { opacity: 1, y: 0 },
   enter: { opacity: 1, y: 0 },
   exit: { opacity: 0, y: 8 },
})

const StyledLists = styled.div`
   padding: 48px 24px;
   background-color: ${({ theme }) => (theme.darkMode ? theme.colors.menuBg : theme.colors.grey_4)};
`

const StyledListElement = styled(StaggerChild)`
   margin-bottom: 32px;

   &:last-of-type {
      margin-bottom: 0;
   }
`

export default Sidebar
