import React from 'react'

import PageIntro from 'components/PageIntro'
import SearchData from 'partials/search/SearchData'

const ComponentNotFound = ({ __typename }) => {
   return (
      <p>
         Component <strong>{__typename}</strong> Not Found
      </p>
   )
}

const SECTIONS_MAP = {
   ContentfulComponentPageIntro: PageIntro,
   ContentfulPartialSearchData: SearchData,
   NotFound: ComponentNotFound,
}

export default function CfConnector(props) {
   const SelectedComponent =
      typeof SECTIONS_MAP[props.__typename] === 'function' ? SECTIONS_MAP[props.__typename] : SECTIONS_MAP.NotFound

   return <SelectedComponent {...props} />
}
