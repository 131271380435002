import styled from 'styled-components'

import media from 'utils/media'

export const Section = styled.section`
   padding-top: 35px;

   ${media.greaterThan('md')`
      padding-top: 79px;
   `};

   ${media.greaterThan('lg')`
      padding-top: 97px;
   `};
`

export const Content = styled.div`
   position: relative;
   padding: 0 24px;

   &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: -6px;
      right: -6px;
      height: 100%;
   }

   span {
      color: ${({ theme }) => theme.colors.hotCinnamon};
      font-size: 24px;
      font-weight: bold;
      line-height: 1.38;
      letter-spacing: 1.5px;
      margin-bottom: 0;
      text-transform: uppercase;
   }

   h1 {
      color: ${({ theme }) => theme.colors.header};
      font-family: ${({ theme }) => theme.fonts.heldane_text};
      font-size: 48px;
      font-weight: 500;
      line-height: 1.31;
   }

   ${media.greaterThan('md')`
      padding: 0 50px;

      span {
         margin-bottom: -8px;
      }

      h1 {
         font-size: 57px;
         line-height: 73px;
      }
   `}

   ${media.greaterThan('lg')`
      padding: 0 50px 46px;

      &::after {
         left: -10px;
         right: -10px;
      }
  `}

  ${media.greaterThan('1070px')`
    &::after {
      left: -50px;
      right: -50px;
    }
  `}

  ${media.greaterThan('1100px')`
    &::after {
      left: -60px;
      right: -60px;
    }
  `}
`
