import React, { useEffect } from 'react'
import { useSearchBox } from 'react-instantsearch-hooks-web'
import styled from 'styled-components'

import media from 'utils/media'
import FontAwesome from 'components/FontAwesome'

export default function SearchBox({ defaultValue, placeholder }) {
   const { refine } = useSearchBox()

   useEffect(() => {
      if (defaultValue) {
         refine(defaultValue)
      }
   }, [])

   return (
      <Wrapper>
         <input
            defaultValue={defaultValue || ''}
            id="algolia_search"
            type="text"
            placeholder={placeholder}
            onChange={(e) => refine(e.currentTarget.value)}
         />

         <FontAwesome icon="search" />
      </Wrapper>
   )
}

const Wrapper = styled.div`
   position: relative;
   padding: 0 24px;
   margin-bottom: 24px;

   ${media.greaterThan('sm')`
      padding: 0 50px;
   `};

   input {
      padding: 8px 16px;
      width: 100%;
      height: 42px;
      border: 2px solid ${({ theme }) => theme.colors.textColor};
      border-radius: 0px;
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.4px;
      color: ${({ theme }) => theme.colors.textColor};
      outline: none;

      &:hover {
         box-shadow: ${({ smallVariant }) => (smallVariant ? '-2px 2px 2px #d8d8d8' : 'none')};
      }

      &:focus {
         border: solid 2px ${({ theme }) => theme.colors.hotCinnamon};
         box-shadow: none;
      }

      &::placeholder {
         font-size: 16px;
         color: ${({ theme }) => theme.colors.grey_3};
      }
   }

   svg {
      padding: 0;
      background-color: transparent;
      border: none;
      position: absolute;
      right: 40px;
      top: 50%;
      transform: translateY(-50%);

      ${media.greaterThan('sm')`
         right: 66px;
      `};
   }
`
