import React from 'react'
import styled from 'styled-components'

import media from 'utils/media'
import Markdown from 'components/Markdown'
import NewsletterForm from 'components/NewsletterForm'

function NewsletterBox({ subscriberText, ctaText }) {
   return (
      <StyledNewsletterBox>
         {subscriberText?.markdown && (
            <StyledText>
               <Markdown>{subscriberText.markdown}</Markdown>
            </StyledText>
         )}
         <NewsletterForm placeholder={ctaText} small />
      </StyledNewsletterBox>
   )
}

const StyledNewsletterBox = styled.div`
   padding: 24px;
   margin-bottom: 24px;
   background-color: ${({ theme }) => (theme.darkMode ? theme.colors.menuBg : theme.colors.grey_4)};

   ${media.greaterThan('md')`
      padding: 40px 24px;
      margin-bottom: 72px;
   `};

   ${media.greaterThan('lg')`
      padding: 24px 27px;
      margin-bottom: 24px;
   `};
`

const StyledText = styled.div`
   margin-bottom: 16px;

   ${media.greaterThan('md')`
      margin-bottom: 24px;
   `};

   ${media.greaterThan('lg')`
      margin-bottom: 16px;
   `};

   h1,
   h2,
   h3 {
      font-size: 24px;
      line-height: 37px;
      color: ${({ theme }) => theme.colors.hotCinnamon};
      font-family: ${({ theme }) => theme.fonts.titillium};
      font-weight: 700;
      margin-bottom: 4px;
   }

   p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;

      &:last-of-type {
         margin-bottom: 0;
      }
   }
`

export default NewsletterBox
