import React, { useState, useEffect } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'
import { graphql } from 'gatsby'

import { DEFAULT_LANG } from 'config/languages'
import { updateListSlugs } from 'utils/helpers'
import Sidebar from 'components/Sidebar'
import Button from 'components/Button'
import Link from 'components/Link'
import { Container, Row, Column } from 'components/Grid'

import SearchInput from './SearchInput'
import Results from './Results'
import { Section } from './styles'

export default function SearchData({
   cta,
   writingPosts,
   podcastPosts,
   videoPosts,
   featuredPosts,
   categories = [],
   authors = [],
   lang,
   value,
   data,
}) {
   const [isAnimated, handleIsAnimated] = useState(false)
   const { resultsLabel, noResultsLabel, backLink, searchPlaceholder } = data
   const searchClient = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_SEARCH_KEY)
   const langPrefix = lang === DEFAULT_LANG ? '/' : `/${lang}/`
   const updatedWritingPosts = updateListSlugs(writingPosts, langPrefix)
   const updatedVideoPosts = updateListSlugs(videoPosts, langPrefix)
   const updatedPodcastPosts = updateListSlugs(podcastPosts, langPrefix)
   const updatedFeaturedPosts = updateListSlugs(featuredPosts, langPrefix)
   const parsedAuthors = authors.map(({ node }) => ({ ...node }))
   const labels = categories.length
      ? Object.assign(
           {},
           ...categories.map(({ node }) => ({
              [node.categoryName]: {
                 url: `${langPrefix}${node.categoryName}/`,
                 label: node.categoryLabel,
              },
           }))
        )
      : {}

   const postLists = {
      featured: {
         category: labels.featured,
         posts: updatedFeaturedPosts,
      },
      writing: {
         category: labels.writing,
         posts: updatedWritingPosts,
      },
      video: {
         category: labels.videos,
         posts: updatedVideoPosts,
      },
      podcast: {
         category: labels.podcasts,
         posts: updatedPodcastPosts,
      },
   }

   useEffect(() => {
      handleIsAnimated(true)
   }, [])

   return (
      <Section>
         <Container>
            <Row justifyContent={['space-between']}>
               <Column width={[1, 1, 1, 6 / 10]} order={[2, 2, 2, 1]}>
                  <InstantSearch
                     searchClient={searchClient}
                     indexName={lang ? `Posts-${lang.split('-')[0]}` : `Posts-en`}
                  >
                     <Configure
                        attributesToHighlight={[]}
                        hitsPerPage={20}
                        attributesToRetrieve={[
                           'id',
                           'node_locale',
                           'slug',
                           'postTitle',
                           'postDate',
                           'timeRead',
                           'postAuthors',
                           'postCategories',
                           'text',
                        ]}
                     />
                     <SearchInput defaultValue={value} placeholder={searchPlaceholder} />
                     <Results resultsLabel={resultsLabel} noResultsLabel={noResultsLabel} authors={parsedAuthors} />
                  </InstantSearch>
                  {backLink && (
                     <Row justifyContent={['center']}>
                        <Link url={backLink.url} type={backLink.type}>
                           <Button>{backLink.title}</Button>
                        </Link>
                     </Row>
                  )}
               </Column>

               <Column width={[1, 1, 1, 4 / 10]} pl={[10, 10, 10, '24px']} order={[1, 1, 1, 2]}>
                  <Sidebar cta={cta} lists={postLists} isAnimated={isAnimated} />
               </Column>
            </Row>
         </Container>
      </Section>
   )
}

export const query = graphql`
   fragment SearchData on ContentfulPartialSearchData {
      id
      searchPlaceholder
      resultsLabel
      noResultsLabel
      backLink {
         type
         url
         title
      }
   }
`
