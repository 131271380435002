import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { navigate } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import FontAwesome from 'components/FontAwesome'
import PostCategories from 'components/PostCategories'

import Cover from './Cover'
import defaultAuthorImg from 'assets/img/mcc_defaultAuthor.png'

import {
   StyledPost,
   StyledPostInfo,
   StyledDate,
   StyledExcerpt,
   StyledAuthors,
   StyledAuthorImg,
   StyledAuthorName,
} from './styles'

export default function PostThumbnail({
   fullWidth,
   postDate,
   slug,
   postCategories,
   postTitle,
   postAuthors,
   timeRead,
   cover,
   postExcerpt,
}) {
   function handleOnClickPost(e) {
      e.stopPropagation()
      navigate(slug)
   }

   return (
      <StyledPost fullWidth={fullWidth} onClick={handleOnClickPost}>
         {Array.isArray(postCategories) && <PostCategories postCategories={postCategories} />}
         <h2>{postTitle}</h2>
         <StyledPostInfo fullWidth={fullWidth}>
            {Array.isArray(postAuthors) ? (
               <StyledAuthors>
                  <div>
                     {postAuthors.map((author, index) => (
                        <StyledAuthorImg key={author.id} n={index}>
                           {author.profilePhoto?.gatsbyImage && (
                              <GatsbyImage image={author.profilePhoto.gatsbyImage} alt={author.name} />
                           )}
                        </StyledAuthorImg>
                     ))}
                  </div>
                  <StyledAuthorName>
                     <span>By </span>
                     {postAuthors.map((author, index) => {
                        if (index > 0) {
                           return (
                              <React.Fragment key={author.id}>
                                 <span> AND </span>
                                 <strong>
                                    {author.socialUrl ? (
                                       <a href={author.socialUrl} target="_blank" rel="noreferrer">
                                          {author.name}
                                       </a>
                                    ) : (
                                       author.name
                                    )}
                                 </strong>
                              </React.Fragment>
                           )
                        }
                        return (
                           <strong key={author.id}>
                              {author.socialUrl ? (
                                 <a href={author.socialUrl} target="_blank" rel="noreferrer">
                                    {author.name}
                                 </a>
                              ) : (
                                 author.name
                              )}
                           </strong>
                        )
                     })}
                  </StyledAuthorName>
               </StyledAuthors>
            ) : (
               <StyledAuthors>
                  <div>
                     <StyledAuthorImg>
                        <img src={defaultAuthorImg} alt="Multicoin capital" />
                     </StyledAuthorImg>
                  </div>
                  <StyledAuthorName>
                     <span>By </span>
                     <strong>Multicoin Capital</strong>
                  </StyledAuthorName>
               </StyledAuthors>
            )}
            <StyledDate className="dateData">
               {postDate} | {timeRead}
               <FontAwesome icon={'clock'} />
            </StyledDate>
         </StyledPostInfo>
         {!!cover && <Cover {...cover} />}
         {!isEmpty(postExcerpt) && <StyledExcerpt>{postExcerpt.text}</StyledExcerpt>}
      </StyledPost>
   )
}
