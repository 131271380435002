import styled from 'styled-components'

import media from 'utils/media'

export const StyledPost = styled.div`
   padding: 24px;
   transition: all 0.55s cubic-bezier(0, 0.89, 0.44, 1) 0s;
   cursor: pointer;
   overflow: auto;

   ${media.greaterThan('md')`
      padding: 40px 50px;

      &:hover {
         box-shadow: ${({ theme }) => theme.colors.postBoxShadow};
         transform: translateY(-4px);
      }
   `};

   ${media.greaterThan('lg')`
      border-bottom: ${({ fullWidth }) => (fullWidth ? 'none' : 'solid 1px #979797')};
   `};

   h2 {
      color: ${({ theme }) => theme.colors.header};
      font-size: 32px;
      line-height: 42px;
      letter-spacing: -0.5px;
      transition: 0.3s cubic-bezier(0, 0.89, 0.44, 1);
      margin-bottom: 0;

      ${media.greaterThan('md')`
        line-height: ${({ fullWidth }) => (fullWidth ? '47px' : '1.08')};
        letter-spacing: ${({ fullWidth }) => (fullWidth ? '-0.5px' : '-0.3px')};
        font-size: ${({ fullWidth }) => (fullWidth ? '38px' : '27px')};
      `};
   }
`

export const StyledPostInfo = styled.div`
   margin-top: 16px;
   margin-bottom: 30px;

   ${media.greaterThan('md')`
      display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'block')};
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;
      margin-bottom: 28px;
   `};

   ${media.greaterThan('lg')`
      display: block;
   `};

   ${media.greaterThan('xl')`
      display: ${({ fullWidth }) => (fullWidth ? 'flex' : 'block')};
   `};
`

export const StyledAuthors = styled.div`
   display: flex;
   align-items: center;
   margin: 12px 0;

   ${media.greaterThan('md')`
      margin: 0;
      flex: 0 0 50%;
   `};

   ${media.greaterThan('lg')`
       margin: 12px 0;
   `};

   ${media.greaterThan('xl')`
       margin: 0;
   `};

   & > div:first-of-type {
      align-items: center;
      margin-right: 8px;
      display: flex;
   }
`

export const StyledAuthorImg = styled.div`
   width: 38px;
   height: 38px;
   border-radius: 50%;
   overflow: hidden;
   background-color: ${({ theme }) => theme.colors.textColor};
   transform: ${({ n }) => `translateX(${n * -5}px)`};
   position: relative;
   z-index: ${({ n }) => 5 - n};
`

export const StyledAuthorName = styled.div`
   text-transform: uppercase;
   font-weight: 300;
   font-size: 16px;
   line-height: 18px;
   color: ${({ theme }) => theme.colors.header};

   strong {
      font-weight: 700;

      a:hover {
         color: ${({ theme }) => theme.colors.hotCinnamon};
      }
   }
`

export const StyledDate = styled.span`
   line-height: 1;
   font-weight: 300;
   display: block;
   font-size: 14px;

   ${media.greaterThan('md')`
      font-size: 16px;
      flex: 0 0 45%;
   `};

   ${media.greaterThan('xl')`
      font-size: 16px;
      flex: 0 0 48%;
   `};

   svg {
      font-size: 14px;
      margin-left: 8px;
      vertical-align: top;
      color: #9b9b9b;
      vertical-align: bottom;
   }
`

export const StyledExcerpt = styled.p`
   line-height: 24px;
   margin-bottom: 0;

   ${media.greaterThan('md')`
      font-size: 18px;
      line-height: 27px;
   `};
`
