import React from 'react'
import { graphql } from 'gatsby'

import { Container, Row, Column } from 'components/Grid'

import { Section, Content } from './styles'

export default function PageIntro({ title, subtitle }) {
   return (
      <Section>
         <Container>
            <Row>
               <Column style={{ boxShadow: 'rgb(0 0 0 / 6%) 0px 30px 30px -30px' }}>
                  <Content>
                     <span>{title}</span>
                     <h1>{subtitle}</h1>
                  </Content>
               </Column>
            </Row>
         </Container>
      </Section>
   )
}

export const query = graphql`
   fragment PageIntro on ContentfulComponentPageIntro {
      id
      title
      subtitle
   }
`
