import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'

import media from 'utils/media'

function VideoCover({ url }) {
   return url ? (
      <StyledVideoCover>
         <iframe
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
         />
      </StyledVideoCover>
   ) : null
}

function PodcastCover({ image, title, url }) {
   return image ? (
      <StyledPodcastCover href={url || '/'}>
         <GatsbyImage image={image.gatsbyImageData} alt={title || ''} />
      </StyledPodcastCover>
   ) : null
}

export default function Cover({ title, coverType, cover, coverVideo, coverPodcast }) {
   function renderCover() {
      switch (coverType) {
         case 'image':
            return <PodcastCover image={cover} title={title} url={coverPodcast} />
         case 'video':
            return <VideoCover url={coverVideo} />
         default:
            return null
      }
   }

   return <StyledCover isImage={coverType === 'image'}>{renderCover()}</StyledCover>
}

const StyledCover = styled.div`
   margin: 24px 0;

   ${media.greaterThan('md')`
      margin: ${({ isImage }) => (isImage ? '0 24px 0 0' : '48px 0 12px')};
      float: ${({ isImage }) => (isImage ? 'left' : 'none')};
   `};
`

const StyledVideoCover = styled.div`
   position: relative;
   overflow: hidden;
   width: 100%;
   padding-top: 56.25%;

   iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
   }
`

const StyledPodcastCover = styled.a`
   width: 100%;
   max-width: 500px;
   margin: 0 auto;
   display: block;

   ${media.greaterThan('md')`
      max-width: 140px;
      margin: 0;
   `};
`
