import React from 'react'

import Link from 'components/Link'

import { StyledHeader, StyledList } from './styles'

function CategorizedList({ title, list, seeAllUrl }) {
   return (
      <div>
         <StyledHeader>
            <span>{title}</span>
            <Link type="internal" url={seeAllUrl} aria-label={`See all ${title} posts`}>
               See all
            </Link>
         </StyledHeader>
         <StyledList>
            {list.map((post) => {
               return (
                  <li key={post.id}>
                     <Link url={post.slug} type="internal" style={{ display: 'block' }}>
                        {post.postTitle}
                     </Link>
                  </li>
               )
            })}
         </StyledList>
      </div>
   )
}

export default CategorizedList
